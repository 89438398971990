#sensore {
  background-image: linear-gradient(to bottom, #00c9fd, #81ee8e);
  .ui.stacked.segment {
    padding: 28px;
    border-radius: 20px;
    &:after {
      content: none;
    }
  }
  .ui.table {
    width: 80%;
    margin: auto;
  }
  .logo {
    margin-bottom: 20px;
    img {
      background-color: #a59a94a6;
      width: 80px;
      border-radius: 14px;
    }
  }
  .data-not-found {
    width: 100%;
    position: absolute;
    right: -6px;
    margin-top: 31px;
  }
  .dimmed.dimmable > .ui.animating.dimmer,
  .dimmed.dimmable > .ui.visible.dimmer,
  .ui.active.dimmer {
    display: flex;
    opacity: 0.7;
  }
  .go-back {
    position: absolute;
    left: 17.2%;
    top: 14%;
    background-color: #e6dfd9;
    padding: 5px;
    border-radius: 7px;
    font-weight: 500;
    font-size: medium;
  }
}
