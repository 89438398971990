#dashboard {
  .ui.grid>.column:not(.row){
    padding-bottom: 0 !important;
  }
  .ui.search>.results{
    display: none !important;
  }
    .show{
        text-align: center;
        margin-top: 25px;
        button{
            border: none;
            color: #00c9fe;
            font-size: 35px;
            background: none;
            cursor: pointer;
        }
    }
    .fourteen{
        background-color: #80808017;
    }
  .ui.inverted.menu {
    border: 0 solid transparent;
    background: #fff;
    box-shadow: none;
  }
  .ui.input > input {
    background-color: #f1f2f3;
    border: none;
    border-radius: 5px;
    color: gray;
  }
  .logo {
    img {
      width: 70px;
      height: 70px;
      // border-radius: 50%;
    }
  }
  .register {
    button {
      background-color: #00c9fe;
      border: none;
      padding: 10px 73px;
      color: #ffff;
      border-radius: 8px;
      cursor: pointer;
      font-size: 15px;
      letter-spacing: 1px;
      transition: all .3s;
      &:hover{
        background-color: #00c9fed4;
      }
    }
  }
  .logout{
    text-align: left;
    button {
      // background-color: #00c9fe;
      margin-top: 20px;
      border: none;
      padding: 10px 20px;
      color: red;
      border-radius: 8px;
      cursor: pointer;
      font-size: 15px;
      letter-spacing: 1px;
      transition: all .3s;
      &:hover{
        background-color: #A8A8A8;
      }
    }
  }
  .tag {
    a {
      color: #1b1c1dba;
      text-align: left;
      font-size: 20px;
      &:hover {
        color: #00c9fe;
      }
    }
  }
  .marker{
    font-size: 25px;
    color: #00c9fe;;
  }
  .view-sensore{
    font-weight: bold;
    background-color: #008aaf;
    display: block;
    margin-top: 15px;
    border: none;
    padding: 10px;
    color: #ffff;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    letter-spacing: 1px;
    transition: all .3s;
  }
}
