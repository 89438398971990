.payment{
    height: 100vh;
    display: flex;
    .payment-inner{
        width: 100%;
        align-self: center;
    }
    .ui.button{
        background-color: #16596b !important;
    }
}