#signin{
    background-image: linear-gradient(to bottom, #00c9fd, #81ee8e);
    .ui.stacked.segment {
        padding: 28px;
        border-radius: 20px;
        &:after{
            content: none;
        }

    }
    .logo {
        margin-bottom: 20px;
        img {
          width: 50px;
          height: 50px;
          // border-radius: 50%;
        }
      }
    .ui.form .field>label {
        display: block;
        margin: 0 0 18px;
        color: #81818a;
        font-size: .92857143em;
        font-weight: 500;
        text-transform: none;
        text-align: left;
    }
    input[type= text] {
        color: #A6A6A7;
    }
    input[type = password] {
        color: #A6A6A7;
        letter-spacing: 6px;
        font-size:1rem;
        font-weight: 700;
    }
   .ui.checkbox label, .ui.checkbox + label {
        color: #A6A6A7;
        transition: color .1s ease;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
    }
    .ui.form .field {
        clear: both;
        margin: 40px 0 0 0;
    }
    .ui.fluid.button {
        width: 75%;
        margin-top: 20px;
    }
    .ui.checkbox {
        position: relative;
        display: inherit;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        outline: 0;
        vertical-align: baseline;
        font-style: normal;
        min-height: 17px;
        font-size: 1rem;
        line-height: 17px;
        min-width: 17px;
        margin: 14px 0;
    }
    .ui.checkbox label, .ui.checkbox+label {
        color: #A5A5A6;
        transition: color .1s ease;
        text-align: left;
    }
    .ui.button {
        cursor: pointer;
        display: inline-block;
        min-height: 1em;
        outline: 0;
        border: none;
        vertical-align: baseline;
        padding: 10px 29%;
        background-color: #19CEFD;
        color: #fff;
        font-weight: 700;
        font-style: normal;
        text-align: center;
        text-decoration: none;
        border-radius: .28571429rem;
        -webkit-user-select: none;
        user-select: none;
        transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
        will-change: '';
        -webkit-tap-highlight-color: transparent;
    }
    .para {
        margin-bottom: 40px;
        font-size: 12px;
        color: #81818a;
        font-weight: 400;
    }
    .already{
        color: #A5A5A6;
        font-size: 12px;
        font-weight: 500;
        margin: 10px 0;
    }
}